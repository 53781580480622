import { Box, Container , Typography , Grid , Button} from "@mui/material";

const Welcome = () => {
    return (  
        <Container align="center" sx={{
            
            backgroundImage: "url('/img/Wallpaper.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "250px",
            pt: 4,
            color: "color.light",
            textShadow: "3px 3px 3px black",
          }}>
            <Typography variant="h4" align="center"  gutterBottom>
            Evo Pluz
            </Typography>
            <Typography variant="h5" align="center"  gutterBottom>
            ដាក់សំលេងចូលខួរក្បាល
            </Typography>
            <Typography variant="h4" align="center"  gutterBottom>
            ទាញអតីតកាលទៅអនាគត
            </Typography>
            <Container>
                            <Typography
                                variant="small"
                                align="center"
                                color="textSecondary"
                                paragraph
                            >
                                Find us on
                            </Typography>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <a href="https://www.facebook.com/EvoPluz">
                                    <Button variant="contained" color="primary" >
                                        Facebook
                                    </Button>
                                        </a>
                                    
                                </Grid>
                                <Grid item>
                                <a href="https://www.youtube.com/EvoPluz">
                                    <Button variant="contained" color="error">
                                        Youtube
                                    </Button>
                                    </a>
                                </Grid>
                            </Grid>
                        </Container>
        </Container>
    );
}
 
export default Welcome;