import { useHistory , useParams } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";
import useFetch from "./useFetch";
import useContents from "./useContents";
const ProductDetails = () => {
    const jsonFile = "/data/products.json";
    const { name } = useParams();
    // const { data: product, error, isPending } = useFetch("http://192.168.86.102:3001/products/" + name);
    const { data: products, error, isPending } = useFetch(jsonFile);

    // console.log(product);
    // const contents = useContents();
    // // Function to find a product by ID
    function getProductById(productId) {
    return products.products.find((product) => product.name === productId);
    }
    // console.log(getProductById(name));
    
    let product ;
    if(products){
        product = getProductById(name);
    }

    return (  
        <div>
            {isPending && <div>Loading...</div>}
            {error && <div>{error}</div>}
            {product && (
                <Container align={"center"} sx={{
                    backgroundColor: "color.second",
                    padding: 4,
                }}>
                    <h2>{product.name}</h2>
                    <img src={product["instrument-img"]} alt={product.name} width={300}/>
                    
                    <p>{product.contents}</p>
                    <img src={"/" + product.img} alt={product.name} width={150} />
                    <Container maxWidth="sm" sx={
                        {
                            margin: 1
                            
                        }
                    
                    }>
                    <Button variant="contained" color="primary" href={product.url} sx={{
                        margin: 1,
                        spacing: 2,
                    
                    }}>
                        Download PC
                    </Button>
                    <Button variant="contained" color="error" href={product.url} sx={{
                        margin: 1,
                        spacing: 2,
                    
                    }}>
                        Download MAC
                    </Button>

                    </Container>
                    
                    

                </Container>
            )}
        </div>
    );
}
 
export default ProductDetails;