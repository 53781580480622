import { createTheme } from "@mui/material";
import { dark } from "@mui/material/styles/createPalette";

const Theme = () => {
    const darkTheme = createTheme({
        palette: {
          background: {
            default: "#232233"
          },
          text: {
            primary: "#ffffff",
            secondary: "#ffffff",
            dark: "#222222",
          },
          card: {
            background: "#222222",
            text: "#ffffff"
          },
          color: {
            first: "#0F0F0F",
            second: "#232D3F",
            third: "#005B41",
            fourth: "#008170",
            fifth: "#CFA6A6",
            sixth: "#E4CFCF",
            seventh: "#F5E4E4",
            eighth: "#FFFFFF",
            blue: "#3F51B5",
            red: "#F44336",
            green: "#4CAF50",
          },
          Toolbar: {
            backgroundColor: "#222222",
            }
        }
      });
        
    return ( 
        darkTheme
     );
}
 
export default Theme;
