import App from './App';

// ReactDOM.render(<App />, document.getElementById("root"));

import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); 
const Sample = () => {
    return ( 
        <div>
            <h1>Sample</h1>
        </div>
     );
    }
    
    

root.render(<App/>);

