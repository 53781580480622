import {
    Grid,
    Container,
    Typography,
    AppBar,
    CssBaseline,
    Toolbar,
    Button,
    createTheme,
    ThemeProvider,
} from "@mui/material";
import { GraphicEq} from "@mui/icons-material";

import Home from "./Home";
import { BrowserRouter as Router , Route , Switch  } from 'react-router-dom';
import ProductDetails from "./ProductDetails";
import Theme from "./Theme";
import "./style.css"
const theme = Theme;
const App = () => {
    
    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />


        <Router>
            
            <AppBar position="relative" >
                <Toolbar className="navbar" >
                    {/* <GraphicEq sx={{ mx: 2}}/> */}
                    <img src="/logo.png" alt="EvoPluz logo" className="logo" />
                    <a href="/">
                    <Typography variant="h6" color="inherit" noWrap>
                        EvoPluz
                    </Typography>
                    </a>
                </Toolbar>
            </AppBar>
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/:name">
                    <ProductDetails/>
                </Route>
            </Switch>
        </Router>
        </ThemeProvider>
    );
};

export default App;
