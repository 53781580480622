import { red } from '@mui/material/colors';
import { dark } from '@mui/material/styles/createPalette';
import { styled } from '@mui/system';

const useStyles = styled((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        //   backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
        //   marginBottom: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        //   height: '100%',
        display: "flex",
        flexDirection: "column",
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
        height: 0,
        width: "100%",
        objectFit: "cover",
    },
    cardContent: {
        flexGrow: 1,
    },
    products: {
        // dark background
        backgroundColor: dark[700],
        textColor: red[500],
        
    },
}));

export default useStyles;