import { useState, useEffect } from 'react';



const useContents = () => {
    const [contents , setContents] = useState(null);
    const productsFile = '/data/products.json';
    useEffect(() => {
        fetch(productsFile)
            .then(res => {
                if(!res.ok) throw Error('Could not fetch the data for that resource');
                return res.json();
            })
            .then(data => {
                setContents(data);
                console.log(data);
            })
            .catch(err => {
                console.log(err.message);
            })
    }, []);

    return contents ;
}
 
export default useContents;