import { Button, Card, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Facebook } from "@mui/icons-material";

const Team = () => {
    return (
        <Container
            className="team"
            align="center"
            sx={{
                mt: 4,
                mb: 4,
                pt: 4,
                pb: 4,
                borderRadius: 2,
                textAlign: "center",
                color: "#fff",
                // dark barckground
                backgroundColor: "color.second",
            }}
        >
            <Typography variant="h4" align="center" color="#ffffff" gutterBottom>
                Team
            </Typography>
            <Typography variant="p" align="center" color="#ffffff" gutterBottom>
                I'm a self-taught programmer, electrical engineer, and Audio Engineer.
                <br />
                Currently, I'm just a single person who makes these plugins with the help of great people who sponsor sample sound.
                <br />
                If you think you can help me with this project, please contact me.
            </Typography>
            <hr />
            <Grid container justifyContent="center" spacing={5}>
                <Grid item>
                    
                <Card sx={{ 
                    maxWidth: 300 ,
                    backgroundColor:"transparent",
                    boxShadow: "none",
                
                }}>
                    <CardMedia
                        component="img"
                        height="300"
                        image="/img/team/evotim.png"
                        alt="Profile Image"
                        sx={{ borderRadius: "50%" ,}}
                        
                    />
                    <CardContent>
                        <Typography variant="h6" align="center" gutterBottom>
                            Evo Tim
                        </Typography>
                        <Typography variant="body2" align="center" color="text.secondary">
                            Audio / Software Engineer
                        </Typography>
                        <Button variant="contained" startIcon={<Facebook />} sx={{ mt: 2 }} href="https://www.facebook.com/mrevotim">
                            Follow
                        </Button>
                    </CardContent>
                </Card>
                </Grid>
                
            </Grid>
        </Container>
    );
};

export default Team;
